$(document).ready(function () {
  // Firebase stuff
  var firebaseConfig = {
    apiKey: "AIzaSyAzQNZGu2lOAf97QNDWiJh2B49ikGFUwXo",
    authDomain: "milk-machine-ff37f.firebaseapp.com",
    projectId: "milk-machine-ff37f",
    storageBucket: "milk-machine-ff37f.appspot.com",
    messagingSenderId: "819962670067",
    appId: "1:819962670067:web:d476cb02d71f50d8a54086",
    measurementId: "G-1QV0N7EPFP",
  };
  // Initialize Firebase
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (e) {
    console.log("Already initialised?", e);
  }

  var storage = firebase.storage();

  var briefFile = null;

  $("#brief-upload").click(function () {
    $(this).val("");
  });

  $("#delete-brief").click(function () {
    console.log("Clicking");
    briefFile = null;
    document.getElementById("brief-file").innerHTML = ``;
  });

  $("#brief-upload").change(function () {
    const types = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const selected = $(this)[0].files[0];

    let file;
    let fileName = selected.name;

    if (selected && types.includes(selected.type)) {
      file = selected;
      uploadPhoto(file, fileName);
    } else {
      file = null;
      setError("Only PNGs, JPEGs, GIFs, PDFs and DOCs are allowed");
    }
    // alert($(this).val());
  });

  const uploadPhoto = async (file, fileName) => {
    let filePath = null;
    let uploadUrl = "";

    if (file == null) {
      return;
    }

    function makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    filePath = `/${makeid(30)}`;
    const storageRef = storage.ref(filePath);

    try {
      // setLoading(true)

      var uploadTask = storageRef.child(filePath).put(file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // setProgress(progress)
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log("Error", error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            uploadUrl = downloadURL;
            briefFile = downloadURL;
            document.getElementById("brief-file").innerHTML = `
                                    <a href="${downloadURL}" target="blank" class="">${fileName}</a><p class="text-sm ml-2 underline cursor-pointer" id="delete-brief">Delete</p>                                `;

            $("#delete-brief").click(function () {
              briefFile = null;
              document.getElementById("brief-file").innerHTML = ``;
            });
          });
        }
      );
    } catch (err) {
      console.log("ERROR HERE", err.message);
      // setError(err.message)
      //error.value = err;
    }
  };

  // Handle form submits
  $("#contact-form").submit(async function (e) {
    // alert("Handler for .submit() called.");
    e.preventDefault();

    $("#form-error").addClass("hidden");

    const name = $("#name").val();
    const email = $("#email").val();
    const phone = $("#phone").val();
    const message = $("#message").val();

    if (name.length === 0) {
      setError("Please enter your name");
      return;
    }

    if (email.length === 0) {
      setError("Please enter your email");
      return;
    }

    if (phone.length === 0) {
      setError("Please enter your phone");
      return;
    }

    if (message.length === 0) {
      setError("Please enter your message");
      return;
    }

    const response = await fetch(
      // "https://elliotmatthews.co.uk/api/mm-contact-form",
      "http://localhost:5173/api/mm-contact",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
          phone: phone,
          briefFile: briefFile,
        }), // body data type must match "Content-Type" header
      }
    );
    console.log("Response", response);
    const json = await response.json();
    console.log("Response", json);
  });

  function setError(string) {
    $("#form-error").text(string);
    $("#form-error").removeClass("hidden");
  }

  $("#copyright").html(`© Milk Machine ${new Date().getFullYear()}`);
});
