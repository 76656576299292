$(document).ready(function () {
  const services = [
    {
      title: "Our Creative Community",
      id: "creative",
      class: "bg-color-2",
      description:
        "We at Milk Machine understand that successful content relies on impact and relevance to create engaging results. Our network of freelance talent gives us access to a multitude of resources, people, tools, and ideas to curate the perfect team for each job. We have creators specialising in all industries, whether your brand operates in sport, fashion, music, design, tech, pets, or even in sending rockets into space - we have the people for you. ",
      scroll:
        "DIRECTORS - CREATIVE TEAMS - STRATEGISTS - EMERGING TALENT - DOPs - PHOTOGRAPHERS - ART DIRECTORS - EDITORS - MOTION GRAPHICS - ",
    },
    {
      title: "Creative Research & Development",
      id: "rd",
      description:
        "Whether it is a branded content film, a documentary, or a comprehensive campaign, our years of industry experience have made us experts at distilling down ideas, building up comprehensive plans, and crafting immersive, meaningful content for our clients.",
      scroll:
        "BRIEF GENERATION - CONCEPT DEVELOPMENT - STORYBOARDING - PROBLEM SOLVING - DIRECTOR SUGGESTIONS - BUDGETING - ",
    },
    {
      title: "Production",
      id: "production",
      description:
        "<p>Milk Machine is able to create efficient productions to match individual timelines and budgets.</p><p>Whether you're looking for a 360-content strategy or just a single piece of communication, we’re at your service. We can create a bespoke package of creators, budgets & schedules to match your project.</p>",
      scroll:
        "COMMERCIAL - MUSIC PROMOS - ANIMATION - VR/AR - DOCUMENTARY - CORPORATE - ONLINE & SOCIAL - ",
    },
  ];

  services.forEach((service) => {
    let scroller = "";
    for (let x = 0; x < 5; x++) {
      scroller += service.scroll;
    }

    $("#services").append(`
        <div class="hidden md:block max-w-full overflow-hidden max-w-sm" data-aos="fade-up">
        <div class="flex items-center text-2xl md:text-2xl space-x-2 cursor-pointer service-header"><h4 class="uppercase cursor-hover" data-service="${service.id}">${service.title}<span class="font-book">  +</span></h4></div>
        <div class="expandable-service max-w-sm" style="opacity: 0; height: 0">
          <div class="slider w-screen overflow-hidden max-w-sm !h-8 mt-3">
              <div class="slide-track !h-8 text-sm">
                  ${scroller}
              </div>
          </div>
          <div class="text-left space-y-2 text-sm ">${service.description}</div>
        </div>
        </div>
    `);
  });

  $(".service-header").click(function () {
    $(".expandable-service").css("opacity", "0").css("height", "0");
    if (
      !$(this).parent().find(".expandable-service").hasClass("open-service")
    ) {
      $(this)
        .parent()
        .find(".expandable-service")
        .css("opacity", "100%")
        .css("height", "100%")
        .addClass("open-service");
    } else {
      $(this).parent().find(".expandable-service").removeClass("open-service");
    }
  });
});
