import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import testVertexShader from "./shaders/test/vertex-about.glsl";
import testFragmentShader from "./shaders/test/fragment-about.glsl";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "./splash-page.js";
import "./menu.js";
import "./clients.js";
import "./work.js";
import "./services.js";
import "./contact.js";
import "./team.js";
import "./blob.js";

// Begin

$(document).ready(function () {
  /**
   * Cursor
   */

  AOS.init();

  let enteredVideo = false;

  // // Canvas
  // const canvas = document.querySelector("canvas.webgl");

  // // Mouse
  // let mouse = {
  //   x: 0,
  //   y: 0,
  // };

  // // Settings
  // let settings = {
  //   // vertex
  //   uFrequency: {
  //     start: 0,
  //     end: 4,
  //   },
  //   uAmplitude: {
  //     start: 4,
  //     end: 4,
  //   },
  //   uDensity: {
  //     start: 1,
  //     end: 1,
  //   },
  //   uStrength: {
  //     start: 0,
  //     end: 1.1,
  //   },
  //   // fragment
  //   uDeepPurple: {
  //     // max 1
  //     start: 0.3,
  //     end: 0,
  //   },
  //   uOpacity: {
  //     // max 1
  //     start: 0.1,
  //     end: 0.66,
  //   },
  // };

  // // const material = new THREE.MeshBasicMaterial({
  // //   color: 0x5f6b9b,
  // //   wireframe: true,
  // //   // color: 0x000000,
  // // });

  // // Scene
  // const scene = new THREE.Scene();

  // /**
  //  * Test mesh
  //  */
  // // Geometry
  // const geometry = new THREE.IcosahedronGeometry(3, 16);

  // //Material
  // const material = new THREE.ShaderMaterial({
  //   vertexShader: testVertexShader,
  //   fragmentShader: testFragmentShader,
  //   side: THREE.DoubleSide,
  //   wireframe: true,
  //   blending: THREE.AdditiveBlending,
  //   transparent: true,
  //   uniforms: {
  //     uFrequency: { value: settings.uFrequency.start },
  //     uAmplitude: { value: settings.uAmplitude.start },
  //     uDensity: { value: settings.uDensity.start },
  //     uStrength: { value: settings.uStrength.start },
  //     uDeepPurple: { value: settings.uDeepPurple.start },
  //     uOpacity: { value: settings.uOpacity.start },
  //   },
  // });

  // // Mesh;
  // const mesh = new THREE.Mesh(geometry, material);
  // mesh.position.set(3, 0, 0);
  // scene.add(mesh);

  // /**
  //  * Sizes
  //  */
  // const sizes = {
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // };

  // window.addEventListener("resize", () => {
  //   // Update sizes
  //   sizes.width = window.innerWidth;
  //   sizes.height = window.innerHeight;

  //   // Update camera
  //   camera.aspect = sizes.width / sizes.height;
  //   camera.updateProjectionMatrix();

  //   // Update renderer
  //   renderer.setSize(sizes.width, sizes.height);
  //   renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  // });

  // window.addEventListener("mousemove", (event) => {
  //   mouse.x = (event.clientX / sizes.width).toFixed(2);
  //   mouse.y = (event.clientY / sizes.height).toFixed(2);
  // });

  // /**
  //  * Camera
  //  */
  // // Base camera
  // const camera = new THREE.PerspectiveCamera(
  //   75,
  //   sizes.width / sizes.height,
  //   0.1,
  //   100
  // );
  // camera.position.set(0, 4, 4);
  // scene.add(camera);

  // // Controls
  // const controls = new OrbitControls(camera, canvas);
  // controls.enableDamping = true;

  // /**
  //  * Renderer
  //  */
  // const renderer = new THREE.WebGLRenderer({
  //   canvas: canvas,
  //   alpha: true,
  //   antialias: true,
  // });
  // renderer.setSize(sizes.width, sizes.height);
  // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  // renderer.setClearColor(0xffffff, 0);

  // /**
  //  * Animate
  //  */
  // const tick = () => {
  //   // Update controls
  //   controls.update();

  //   // Render
  //   renderer.render(scene, camera);

  //   // Call tick again on the next frame
  //   window.requestAnimationFrame(tick);
  // };

  // tick();

  /**
   * GSAP
   */

  gsap.registerPlugin(ScrollToPlugin);

  // $(window).scroll(function () {
  //   var scrollPos = $(document).scrollTop();
  //   gsap.to(mesh.material.uniforms.uFrequency, {
  //     value: scrollPos,
  //   });

  //   gsap.to(mesh.material.uniforms.uAmplitude, {
  //     value: scrollPos * 0.01,
  //   });

  //   gsap.to(mesh.material.uniforms.uDensity, {
  //     value: scrollPos,
  //   });
  // });

  $(".menu-link").click(() => {
    $(".hamburger").toggleClass("is-active");
    menuOpen = false;
    hideMenu();
  });

  $(".scroll-link").bind("click", function (e) {
    e.preventDefault();
    setTimeout(() => {
      smoother.scrollTo($(this).attr("href"), true);
    }, 100);
  });

  var menuOpen = false;

  const hideMenu = () => {
    const menuLinksQuery = document.querySelectorAll(".menu-link");

    for (let x = 0; x < menuLinksQuery.length; x++) {
      gsap.to(menuLinksQuery[x], {
        opacity: "0",
        ease: "ease",
        duration: 0.5,
      });
    }

    gsap.to("#menu-icons", {
      opacity: "0",
      ease: "sine",
      duration: 0.5,
    });

    gsap.to("#madcowlogo", {
      opacity: "0",
      ease: "sine",
      duration: 0.5,
    });

    gsap.to("#menu", {
      left: "100%",
      ease: "sine",
      duration: 0.6,
    });
  };

  $(".hamburger").click(function () {
    $(this).toggleClass("is-active");

    // Hiding Menu
    if (menuOpen) {
      hideMenu();

      // Showing Menu
    } else {
      const menuLinksQuery = document.querySelectorAll(".menu-link");
      gsap.to("#menu", {
        left: window.innerHeight < 800 ? "0%" : "50%",
        ease: "sine",
        duration: 0.6,
      });

      for (let x = 0; x < menuLinksQuery.length; x++) {
        gsap.to(menuLinksQuery[x], {
          opacity: "100%",
          ease: "sine",
          duration: 0.2,
          delay: (x + 1) * 0.1,
        });
      }

      gsap.to("#menu-icons", {
        opacity: "100%",
        ease: "sine",
        duration: 0.2,
        delay: 0.8,
      });

      gsap.to("#madcowlogo", {
        opacity: "100%",
        ease: "sine",
        duration: 0.2,
        delay: 1,
      });
    }

    menuOpen = !menuOpen;
  });

  $(".learn-more-btn").mouseenter(function () {
    let index = $(this).attr("data-index");

    gsap.to(`#panel-${index}-services`, {
      display: "flex",
      duration: 0.1,
      onComplete: () => {
        gsap.to(`#panel-${index}-services`, {
          opacity: "100%",
          ease: "sine",
          duration: 0.4,
        });
      },
    });
  });

  $(".learn-more-btn").mouseleave(function () {
    let index = $(this).attr("data-index");

    gsap.to(`#panel-${index}-services`, {
      opacity: 0,
      ease: "sine",
      duration: 0.4,
      onComplete: () => {
        gsap.to(`#panel-${index}-services`, {
          display: "none",
          duration: 0.1,
        });
      },
    });
  });

  // Animated cursor
  // Cursor
  var cursor = $(".cursor");

  $(window).mousemove(function (e) {
    cursor.css({
      top: e.clientY - cursor.height() / 2,
      left: e.clientX - cursor.width() / 2,
    });
  });

  $(window)
    .mouseleave(function () {
      cursor.css({
        opacity: "0",
      });
    })
    .mouseenter(function () {
      cursor.css({
        opacity: "1",
      });
    });

  $(".link")
    .mouseenter(function () {
      cursor.css({
        transform: "scale(3.2)",
      });
    })
    .mouseleave(function () {
      cursor.css({
        transform: "scale(1)",
      });
    });

  $("a")
    .mouseenter(function () {
      cursor.css({
        transform: "scale(3.2)",
      });
    })
    .mouseleave(function () {
      cursor.css({
        transform: "scale(1)",
      });
    });

  $(".cursor-hover")
    .mouseenter(function () {
      cursor.css({
        transform: "scale(3.2)",
      });
    })
    .mouseleave(function () {
      cursor.css({
        transform: "scale(1)",
      });
    });

  $(window)
    .mousedown(function () {
      cursor.css({
        transform: "scale(.2)",
      });
    })
    .mouseup(function () {
      cursor.css({
        transform: "scale(1)",
      });
    });
});
