import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

// Begin
$(document).ready(async function () {
  // Fetch behold posts first
  // const res = await fetch("https://feeds.behold.so/RteMB7l6Uh3DcYJjpBnb");
  // const data = await res.json();

  // Milk Machine
  // const res2 = await fetch("https://feeds.behold.so/97b4WIR94VlOihkxpDqB");
  // const data2 = await res2.json();
  // const instagramFeed = [...data, ...data2];
  const instagramFeed = [];

  console.log("Instagram posts", instagramFeed);

  const fetchMediaUrlForInstagramPost = (id) => {
    if (!instagramFeed) return null;

    for (const post of instagramFeed) {
      if (post.id === id) {
        return post.mediaUrl;
      }
      if (post.children) {
        for (const child of post.children) {
          if (child.id === id) {
            return child.mediaUrl;
          }
        }
      }
    }

    return null;
  };

  var db = firebase.firestore();

  // Get hero video
  let heroVid = "";
  await db
    .collection("hero-video")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        const data = doc.data();
        heroVid = data.video;
      });
    });

  let work = [];

  await db
    .collection("list")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        const data = doc.data();
        work = data.masonry;
      });
    });

  console.log("Work new", work);

  function generateProject(project, index) {
    let string = "";

    string += `
              <div id="${
                "work-video-" + (index + 1)
              }" class="relative aspect-video work-video cursor-hover" data-title="${
      project.title
    }" data-video="${project.madcowVideoUrl}" data-hover-effect="${
      project.hoverEffect
    }"} >              
                
                  <div class="bg overflow-hidden aspect-video  ${
                    project.hoverEffect ? "bg-hover-blur" : ""
                  }">`;

    if (project.externalLink) {
      string += `<a href="${project.externalLink}" target="blank">`;
    }

    if (project.thumbnailDesktopVideo) {
      string += `<video
        id="video${index + 1}"
        loop
        playsinline
        muted
        autoplay="true"
        class="work-video-desktop"
        
        src="${project.thumbnailDesktopVideo}.mp4"
      ></video>`;
    } else if (project.image) {
      string += `<img src="${
        project.image && project.instagramMediaId
          ? fetchMediaUrlForInstagramPost(project.instagramMediaId)
          : project.image
      }" alt="" class="work-video-desktop" />`;
    } else if (project.video) {
      string += `<video
        
        loop
        playsinline
        muted
        autoplay="true"
        class="work-video-desktop"
        src="${
          project.video && project.instagramMediaId
            ? fetchMediaUrlForInstagramPost(project.instagramMediaId)
            : project.video
        }"
      ></video>`;
    } else if (project.iframe) {
      string += project.iframe;
    }

    if (project.hoverEffect) {
      string += `<div class="hidden md:flex overlay !text-milk flex-col text-xl md:text-3xl" style="${
        `background-color: ` +
        (project.hoverToColour ? project.hoverColor : "rgba(0, 0, 0, 0.2)")
      }">
                      <h2>${project.title}</h2>
                      ${
                        project.subTitle
                          ? `<h4 class="text-xs opacity-70">${project.subTitle}</h4>`
                          : ""
                      }
                    </div>
                  </div>                  
            `;
    }

    string += `
    <div class="overlay absolute !text-milk flex flex-col text-xl md:text-3xl md:hidden w-full h-full inset-0  justify-center items-center" >
                      <h2>${project.title}</h2>
                      ${
                        project.subTitle
                          ? `<h4 class="text-xs opacity-70">${project.subTitle}</h4>`
                          : ""
                      }
                    </div>
                  </div>   
    `;

    if (project.externalLink) {
      string += `</a>`;
    }

    return string;
  }

  function generateProjectMobile(project, index) {
    let string = "";

    string += `
              <div id="${
                "work-video-" + (index + 1)
              }" class="relative aspect-video work-video cursor-hover" data-title="${
      project.title
    }" data-video="${project.madcowVideoUrl}" data-hover-effect="${
      project.hoverEffect
    }"} >              
                
                 `;

    if (project.thumbnailMobileImage) {
      string += `<img src="${project.thumbnailMobileImage}" alt="" class="max-h-full max-w-full aspect-video object-cover" />`;
    }

    string += `
    <div class="overlay absolute !text-milk flex flex-col text-xl md:text-3xl md:hidden w-full h-full inset-0  justify-center items-center" >
                      <h2>${project.title}</h2>
                      ${
                        project.subTitle
                          ? `<h4 class="text-xs opacity-70">${project.subTitle}</h4>`
                          : ""
                      }
                      
                    </div>
                  </div>   
    `;

    return string;
  }

  work.forEach((project, index) => {
    // $("#work-inner").append(`
    //           <div id="${
    //             "work-video-" + (index + 1)
    //           }" class="work-video relative md:absolute cursor-pointer ${
    //   project.class
    // }" data-title="${project.title}" data-video="${
    //   project.video
    // }" style="margin-top: ${project.top}; margin-left: ${
    //   project.left
    // }; width: 17%" data-random="${Math.ceil(
    //   Math.random() * 20
    // )}" data-margin-top="${project.top}" >
    //             <div class="bg">

    //               <video id="video${
    //                 index + 1
    //               }" loop crossOrigin="anonymous" playsinline muted autoplay="true"
    //                 src="/thumbnails/${project.preview}.mp4">
    //             </video>
    //             <div class="overlay">
    //                 <h2>${project.title}</h2>

    //               </div>

    //             </div>
    //           </div>

    //         `);

    // $("#work").append(`
    //           <div id="${
    //             "work-video-" + (index + 1)
    //           }" class="work-video-grid relative cursor-pointer" data-title="${
    //   project.title
    // }" data-video="${project.video}"  data-random="${Math.ceil(
    //   Math.random() * 20
    // )}" data-margin-top="${project.top}" >
    //             <div class="bg">

    //               <video id="video${
    //                 index + 1
    //               }" loop crossOrigin="anonymous" playsinline muted autoplay="true"
    //                 src="/thumbnails/${project.preview}.mp4">
    //             </video>
    //             <div class="overlay">
    //                 <h2>${project.title}</h2>

    //               </div>

    //             </div>
    //           </div>

    //         `);

    // Video thumbnail desktop
    // <img
    //   id="video${index + 1}"
    //   src="/thumbnails/${
    //   project.preview
    // }.gif"
    //   class="work-video-desktop h-full object-cover w-full"
    // />;

    $(".grid-wrapper").append(generateProject(project, index));

    // if (index < 10) {
    //   $("#work-mobile").append(`
    //           <div class="work-video mobile-work-video" data-title="${
    //             project.title
    //           }" data-video="${project.video}">
    //             <div class="bg">

    //               <video id="video${
    //                 index + 1
    //               }" loop crossOrigin="anonymous" playsinline muted autoplay="true" preload="metadata"
    //                 src="/thumbnails/${project.preview}.mp4">
    //             </video>
    //             <div class="overlay">
    //                 <h2>${project.title}</h2>

    //               </div>

    //             </div>
    //           </div>

    //         `);
    // }

    if (index < 6) {
      $("#work-mobile").append(generateProjectMobile(project, index));
    }
  });

  // $(
  //   "#hero-video"
  // ).append(`<video id="video" loop muted playsinline class="absolute inset-0 w-full h-full object-cover"
  //                   webkit-playsinline>
  //                   <source type="video/mp4"
  //                       src="${heroVid}" />

  //               </video>`);

  const workItems = document.querySelectorAll(".work-video");
  // console.log("Work items", workItems);

  workItems.forEach((item, index) => {
    // console.log(item.attr("data-title"));
    $(item).mouseenter(() => {
      // console.log("Hovering item with index", index);
    });

    var menuOpen = false;

    $(item).click(function () {
      // console.log("Cliking item", item);

      const dataVideo = $(item).attr("data-video");
      // console.log("Data video", dataVideo);
      if (!dataVideo) return;

      const heroVideo = document.getElementById("video");
      heroVideo.pause();
      // Hiding Menu
      if (menuOpen) {
        // console.log("Hide");
        gsap.to("#video-player-container", {
          left: "100%",
          ease: "sine",
          duration: 0.6,
        });

        // Showing Menu
      } else {
        // console.log("Show");
        gsap.to("#video-player-container", {
          left: "0%",
          ease: "ease",
          duration: 0.6,
        });

        var oldURL = window.location.href;
        var type = "Active";
        if (history.pushState) {
          var newUrl =
            oldURL +
            "?video=" +
            $(item).attr("data-title").replaceAll(" ", "").toLowerCase();
          window.history.pushState({ path: newUrl }, "", newUrl);
        }
      }

      $("#video-player-title").text($(item).attr("data-title"));

      const video = document.getElementById("video-player");

      video.src = $(item).attr("data-video");

      video.play();

      menuOpen = !menuOpen;
    });
  });

  $("#video-player-close").click(function () {
    gsap.to("#video-player-container", {
      left: "100%",
      ease: "sine",
      duration: 0.6,
    });

    var newUrl = window.location.origin;
    window.history.pushState({ path: newUrl }, "", newUrl);

    const video = document.getElementById("video-player");
    video.pause();

    const heroVideo = document.getElementById("video");
    heroVideo.play();
  });

  $("#video-player-close2").click(function () {
    gsap.to("#video-player-container", {
      left: "100%",
      ease: "sine",
      duration: 0.6,
    });

    var newUrl = window.location.origin;
    window.history.pushState({ path: newUrl }, "", newUrl);

    const video = document.getElementById("video-player");
    video.pause();

    const heroVideo = document.getElementById("video");
    heroVideo.play();
  });

  // Fetch query parameters on page load
  const urlParams = new URLSearchParams(window.location.search);
  const videoParam = urlParams.get("video");
  if (videoParam) {
    gsap.to("#video-player-container", {
      left: "0%",
      ease: "ease",
      duration: 0.6,
    });

    const item = work.find(
      (x) => x.title.replaceAll(" ", "").toLowerCase() === videoParam
    );

    $("#video-player-title").text(item.title);
    $("#video-player-url").text(item.video);

    // const video = document.getElementById("video-player");
    // video.src = item.madcowVideoUrl;
    // video.play();

    const video = document.getElementById("video-player");
    video.src = $(item).attr("data-video");
    // video.play();
    // video.remove();

    // const newVideo = document.createElement("video");
    // newVideo.src = item.madcowVideoUrl;

    // document.getElementById("video-player-container").appendChild(newVideo);
  }
});
