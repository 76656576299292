// Begin
$(document).ready(function () {
  const team = [
    {
      name: "Harry Moore",
      title: "Head of Production",
      email: "harry@milkmachine.co.uk",
      tel: "+447391742997",
      file: "harry",
    },
    {
      name: "Francesco Loy Bell",
      title: "Creative Director",
      email: "francesco@milkmachine.co.uk",

      file: "franceso",
    },
    {
      name: "Nelta Kasparian",
      title: "Partnerships Manager",
      email: "nelta@milkmachine.co.uk",
      tel: "+447549464648",
      file: "nelta",
    },
    // {
    //   name: "Justin Hackney",
    //   title: "Creative Director",
    //   email: "justin@milkmachine.co.uk",
    //   tel: "+447906768943",
    //   file: "justin",
    // },
  ];

  //<video class="team-video w-4/5 md:w-1/3 mx-auto md:w-full mb-4" muted autoplay="true" src="./${member.file}.mp4" loop playsinline></video>

  team.forEach((member, index) => {
    $("#team-members").append(/*html*/ `
      <div class="flex">        
        <div class="text-black space-y-0 uppercase text-left ">
          <h5 class="text-3xl uppercase condensed-black">${member.name}</h5>
          <div>${member.title}</div>
          <div>
          <a href="mailto:${member.email}">
          ${member.email}
          </a>
          </div>
          ${
            member.tel
              ? `<div>          
          <a href="tel:${member.tel}">
          ${member.tel}
          </a>          
          </div>`
              : ""
          }
        </div>
      </div>
    `);
  });

  const videos = document.querySelectorAll(".team-video");

  for (const video of videos) {
    var intervalRewind;

    $(video)
      .on("mouseout", function (event) {
        clearInterval(intervalRewind);
        this.play();
      })
      .on("mouseover", function (event) {
        intervalRewind = setInterval(function () {
          video.pause();
          video.currentTime -= 0.1;
          if (video.currentTime == 0) {
            clearInterval(intervalRewind);
          }
        }, 25);
      });
  }
});
