import gsap from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

$(document).ready(function () {
  gsap.registerPlugin(MorphSVGPlugin);

  // Populate menu items
  const menuLinks = [
    {
      text: "About + Services",
      href: "#about",
    },
    {
      text: "Work + Capabilities",
      href: "#work",
    },
    {
      text: "Team + Contact",
      href: "#team",
    },
  ];

  for (const link of menuLinks) {
    $("#menu-links").append(`
    <div>

                    <a href="${link.href}" class="cosmo menu-link hover-underline-animation" style="opacity: 0%;">
                    <h5>${link.text}</h5>                    
                    </a>
                  </div>
                `);
  }

  // Morph Logo
  var tl = gsap.timeline({
      defaults: { duration: 1 },
      repeat: -1,
    }),
    logo = document.getElementById("m-outside");

  tl.to(logo, { morphSVG: "#m-inside", delay: 2 }).to(logo, {
    morphSVG: logo,
    delay: 2,
  });
});
