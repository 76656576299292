import gsap from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

$(document).ready(function () {
  const wordsTop = ["milk", "machine"];
  const wordsBottom = ["a", "creative", "production", "system"];

  // Top Words
  let string = `<div class="flex items-center space-x-1 md:space-x-3 text-5xl md:text-8xl">`;

  wordsTop.forEach((word) => {
    const letters = word.split("");
    string += `<div class="hero-word">`;
    letters.forEach((letter) => {
      string += `<div class="animated-character relative" style="bottom: -5px;">
                   
                                <div class="inner-character relative">${letter}</div>
                                
                            </div>`;
    });

    string += "</div>";
  });
  string += "</div>";
  // $("#hero-text").append(string);

  // Bottom Words
  string = `<div class="flex flex-wrap justify-center items-center space-x-1 md:space-x-2 text-xl md:text-3xl">`;

  wordsBottom.forEach((word) => {
    const letters = word.split("");

    string += `<div class="hero-word">`;
    letters.forEach((letter) => {
      string += `<div class="animated-character relative" style="bottom: 0px;">
                        
                           <div class="inner-character relative text-base font-medium">${letter}</div>
                            
                          </div>`;
    });

    string += "</div>";
  });
  string += "</div>";
  // $("#hero-text").append(string);

  gsap.registerPlugin(MorphSVGPlugin);

  gsap.to(".reveal", {
    left: "100%",
    ease: "sine",
    duration: 1,
  });

  // gsap.to(".reveal2", {
  //   right: "100%",
  //   ease: "sine",
  //   duration: 1,
  // });

  gsap.to(".reveal", {
    opacity: "0%",
    ease: "sine",
    duration: 0.75,
    delay: 1,
  });

  // gsap.to("#splash-fade1", {
  //   opacity: "100%",
  //   ease: "sine",
  //   duration: 0.75,
  //   delay: 1,
  // });

  // gsap.to(".splash-fade2", {
  //   opacity: "100%",
  //   ease: "sine",
  //   duration: 0.75,
  //   delay: 1.25,
  // });

  // gsap.to("#enter-website", {
  //   opacity: "100%",
  //   ease: "sine",
  //   duration: 0.75,
  //   delay: 1.5,
  // });

  // var tl = gsap.timeline({
  //     defaults: { duration: 1 },
  //     repeat: 0,
  //     onComplete: enterWebsite,
  //   }),
  //   circle = document.getElementById("circle2");

  // tl.to(circle, { morphSVG: "#inside", delay: 2 });

  // $("#splash-video").on("loadstart", function () {
  //   console.log("Splash video loaded");
  // });

  var vid = document.getElementById("splash-video");
  vid.onloadeddata = function () {
    // alert("Browser has loaded the current frame");
    vid.play();
    setTimeout(() => {
      enterWebsite();
    }, 2000);
  };

  // setTimeout(() => {
  //   enterWebsite();
  // }, 3000);

  function enterWebsite() {
    // Play video
    // let video = document.getElementById("video");
    // video.play();

    gsap.to(".splash", {
      height: "0",
      top: "-100%",
      duration: 1,
    });

    gsap.to(".splash-white", {
      height: "0",
      top: "-100%",
      delay: 0.4,
      duration: 0.8,
    });

    // Animate in logo and hamburger
    gsap.to("#hamburger", {
      marginRight: "0",
      duration: 1,
    });

    gsap.to("#nav-logo", {
      marginLeft: "0",
      duration: 1,
    });

    // Animate in hero text
    const heroWords = document.querySelectorAll(".hero-word");

    for (let x = 0; x < heroWords.length; x++) {
      gsap.to(heroWords[x], {
        opacity: "100%",
        ease: "sine",
        duration: 0.5,
        delay: (x + 1) * 0.3,
      });
    }
  }

  // Button effects
  $(".button_su_inner").mouseenter(function (e) {
    var parentOffset = $(this).offset();

    var relX = e.pageX - parentOffset.left;
    var relY = e.pageY - parentOffset.top;
    $(this).prev(".su_button_circle").css({ left: relX, top: relY });
    $(this).prev(".su_button_circle").removeClass("desplode-circle");
    $(this).prev(".su_button_circle").addClass("explode-circle");
    $(this).css({ color: "#000" });
  });

  $(".button_su_inner").mouseleave(function (e) {
    var parentOffset = $(this).offset();

    var relX = e.pageX - parentOffset.left;
    var relY = e.pageY - parentOffset.top;
    $(this).prev(".su_button_circle").css({ left: relX, top: relY });
    $(this).prev(".su_button_circle").removeClass("explode-circle");
    $(this).prev(".su_button_circle").addClass("desplode-circle");
    $(this).css({ color: "#FFF" });
  });

  let hoverClass = 1;
  $("#enter-website").mouseenter(function () {
    $(this).addClass(`btn${hoverClass}`);
  });

  $("#enter-website").mouseleave(function () {
    $(this).removeClass(`btn${hoverClass}`);
    if (hoverClass === 3) {
      hoverClass = 1;
    } else {
      hoverClass++;
    }
  });
});
