$(document).ready(function () {
  const clients = [
    "land-rover",
    "microsoft",
    "chelsea",
    "zeiss",
    "first-we-feast",
    "hbo",
    "ikea",
    "lego",
    "shelter",
    "moneybox",
    "mindful-chef",
    "nike",
  ];

  clients.forEach((client) => {
    $("#slider-logos").append(
      `<div class="slide 2"><img src="./logos/${client}.svg" height="60" width="60" alt="" class="logo" /></div>`
    );
  });

  clients.forEach((client) => {
    $("#slider-logos").append(
      `<div class="slide 2"><img src="./logos/${client}.svg" height="60" width="60" alt="" class="logo" /></div>`
    );
  });

  clients.forEach((client) => {
    $("#slider-logos").append(
      `<div class="slide 2"><img src="./logos/${client}.svg" height="60" width="60" alt="" class="logo" /></div>`
    );
  });

  clients.forEach((client) => {
    $("#slider-logos").append(
      `<div class="slide 2"><img src="./logos/${client}.svg" height="60" width="60" alt="" class="logo" /></div>`
    );
  });

  clients.forEach((client) => {
    $("#slider-logos").append(
      `<div class="slide 2"><img src="./logos/${client}.svg" height="60" width="60" alt="" class="logo" /></div>`
    );
  });
});
